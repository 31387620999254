import {
	Box,
	Grid,
	Typography,
	createStyles,
	makeStyles,
	useTheme,
} from '@material-ui/core';
import { Colors, Fonts } from '../../utils/styles/DefaultTheme';
import React, { useContext, useMemo, useState } from 'react';

import { CardContext } from '../../context/cardContext';
import Countdown from 'react-countdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Raffle } from '../../lib/types';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useRafflesStore } from '../../hooks/useRafflesStore';
import { useWallet } from '@solana/wallet-adapter-react';

const useStyle = makeStyles((theme) =>
	createStyles({
		bodyCardContainer: {
			background: Colors.SECONDARY,
			padding: theme.spacing(1.25),
			position: 'relative',
		},
		imgContainer: {
			width: '100%',
			'& img': {
				width: '100%',
				height: 'auto',
			},
		},
		title: {
			fontFamily: Fonts.Bebas,
			fontSize: '1.5rem',
			fontWeight: 400,
			color: Colors.black,
			display: 'block',
			marginTop: theme.spacing(1.25),
			position: 'relative',
			paddingBottom: theme.spacing(0.5),
			marginBottom: theme.spacing(1),
			textTransform: 'uppercase',
			'&:after': {
				position: 'absolute',
				content: '""',
				width: '100%',
				height: '1px',
				opacity: 0.1,
				background: Colors.black,
				bottom: 0,
				left: 0,
			},
		},
		info: {
			fontFamily: Fonts.ShareTech,
			fontSize: '1.125rem',
			color: Colors.black,
		},
		timerBox: {
			height: '32px',
			width: '32px',
			border: '1px solid rgba(0, 0, 0, 0.6)',
			marginRight: theme.spacing(0.25),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		timerInfo: {
			fontFamily: Fonts.ShareTech,
			fontSize: '1rem',
			color: Colors.black,
		},
		greyCtaBtn: {
			width: '100%',
			marginTop: theme.spacing(2),
			padding: theme.spacing(1, 2),
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			border: 0,
			outline: 0,
			fontFamily: Fonts.Bebas,
			fontSize: '1.25rem',
			color: Colors.black,
			textTransform: 'uppercase',
			cursor: 'pointer',
			background: Colors.grey,
			'&:hover': {
				background: Colors.black,
				color: Colors.SECONDARY,
			},
			'& svg': {
				marginLeft: theme.spacing(1),
			},
		},
		badge: {
			position: 'absolute',
			top: 0,
			left: theme.spacing(2.5),
			background:
				'linear-gradient(93.09deg, #FF00C7 -30.79%, #00FFD1 42.53%, #FFF500 118.97%)',
			padding: theme.spacing(0.75, 1.5),
		},
		badgeInfo: {
			fontFamily: Fonts.Bebas,
			fontSize: '1.25rem',
			color: Colors.black,
			textTransform: 'uppercase',
		},
		ctaBtn: {
			width: '100%',
			marginTop: theme.spacing(2),
			padding: theme.spacing(1, 2),
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			border: `3px solid ${Colors.black}`,
			fontFamily: Fonts.Bebas,
			fontSize: '1.25rem',
			color: Colors.black,
			textTransform: 'uppercase',
			cursor: 'pointer',
			background: Colors.SECONDARY,
			'&:hover': {
				background: Colors.black,
				color: Colors.SECONDARY,
			},
			'& svg': {
				marginLeft: theme.spacing(1),
			},
		},
	})
);

export interface HomeBodyCardType {
	image: string;
	title: string;
	entries: number;
	winner: number;
	time?: number | string;
	numUniqueWallets: number;
	ticketPrice: number;
	raffleId: string;
	raffle: Raffle;
	description: string;
	total: number;
}

interface HomeBodyCardProps extends HomeBodyCardType {}

interface renderTimeType {
	hours: number;
	days: number;
	minutes: number;
	seconds: number;
	completed: boolean;
}

export default function HomeBodyCard({
	image,
	title,
	entries,
	winner,
	time,
	numUniqueWallets,
	ticketPrice,
	raffleId,
	raffle: raffleOld,
	description,
	total,
}: HomeBodyCardProps) {
	const classes = useStyle();
	const theme = useTheme();
	const [card, setCard] = useContext(CardContext);
	const [timerEnded, setTimerEnded] = useState(false);

	const { publicKey } = useWallet();
	const navigate = useNavigate();

	const { raffles } = useRafflesStore();
	const raffle = useMemo(() => raffles.get(raffleId), [raffleId, raffles]);

	const onClickJoinRaffle = () => {
		const card: HomeBodyCardType = {
			title,
			image,
			entries,
			winner,
			time,
			numUniqueWallets,
			ticketPrice,
			raffleId,
			raffle,
			description,
			total,
		};
		setCard?.(card);

		if (timerEnded) {
			navigate('/raffle-table');
		} else {
			navigate('/raffle-buy');
		}
	};

	const userHasJoined = React.useMemo(() => {
		return !!(raffle?.entrants?.get(publicKey?.toBase58()) || false);
	}, [publicKey, raffle]);

	const renderer = ({
		days,
		hours,
		minutes,
		seconds,
		completed,
	}: renderTimeType) => {
		if (completed) {
			setTimerEnded(true);
			return (
				<Box display={'flex'}>
					<Box
						style={{
							border: '1px solid #FFCCCC',
							padding: theme.spacing(0.5, 1),
							height: '30px',
						}}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Typography
							style={{
								fontFamily: Fonts.ShareTech,
								color: '#FF0000',
								fontSize: '0.9rem',
							}}
						>
							ENDED
						</Typography>
					</Box>
				</Box>
			);
		} else {
			return (
				<Box display={'flex'}>
					<Box className={classes.timerBox}>
						<Typography
							style={{
								color: days === 0 && hours === 0 ? '#FF0000' : Colors.black,
							}}
							className={classes.timerInfo}
						>
							{days}d
						</Typography>
					</Box>
					<Box className={classes.timerBox}>
						<Typography
							style={{
								color: days === 0 && hours === 0 ? '#FF0000' : Colors.black,
							}}
							className={classes.timerInfo}
						>
							{hours}h
						</Typography>
					</Box>
					<Box className={classes.timerBox}>
						<Typography
							style={{
								color: days === 0 && hours === 0 ? '#FF0000' : Colors.black,
							}}
							className={classes.timerInfo}
						>
							{minutes}m
						</Typography>
					</Box>
					<Box className={classes.timerBox}>
						<Typography
							style={{
								color: days === 0 && hours === 0 ? '#FF0000' : Colors.black,
							}}
							className={classes.timerInfo}
						>
							{seconds}s
						</Typography>
					</Box>
				</Box>
			);
		}
	};

	return (
		<Grid item md={3} xs={12}>
			<Box className={classes.bodyCardContainer} onClick={onClickJoinRaffle}>
				<Box className={classes.imgContainer}>
					<img src={image} alt="peblo" />
				</Box>
				<Typography className={classes.title}>{title}</Typography>
				<Typography className={classes.info}>
					<span style={{ opacity: 0.6 }}>Entries:</span> {entries} / {total}
				</Typography>
				<Typography className={classes.info}>
					<span style={{ opacity: 0.6 }}>Winners:</span> {winner}
				</Typography>
				<Box
					display={'flex'}
					justifyContent="space-between"
					flexWrap={'wrap'}
					alignItems={'center'}
					width={'100%'}
				>
					<Typography className={classes.info}>
						<span style={{ opacity: 0.6 }}>Ends in:</span>
					</Typography>
					<Countdown date={time} renderer={renderer} />
				</Box>
				<button
					className={timerEnded ? classes.greyCtaBtn : classes.ctaBtn}
					// onClick={onClickJoinRaffle}
				>
					{timerEnded
						? 'view results'
						: userHasJoined
						? 'buy more tickets'
						: 'join raffle '}
					<FontAwesomeIcon icon={faAngleRight} />
				</button>
				<Box
					style={{
						background: timerEnded
							? Colors.SECONDARY
							: 'linear-gradient(93.09deg, #FF00C7 -30.79%, #00FFD1 42.53%, #FFF500 118.97%)',
					}}
					className={classes.badge}
				>
					<Typography
						style={{
							color: timerEnded ? '#FF1111' : Colors.black,
						}}
						className={classes.badgeInfo}
					>
						{timerEnded ? 'ended' : 'ongoing'}
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
}
