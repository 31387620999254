import {
	Box,
	Typography,
	createStyles,
	makeStyles,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { Colors, Fonts } from '../../utils/styles/DefaultTheme';

import bleckImg from '../../assets/images/bleck.png';
import mobileBleckImg from '../../assets/images/mobile-bleck.png';
import overlayBleckImg from '../../assets/images/overlay-bleck.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const useStyle = makeStyles((theme) =>
	createStyles({
		landing: {
			width: '100vw',
			height: '100vh',
			overflow: 'hidden',
			position: 'relative',
			backgroundSize: 'cover !important',
			backgroundPosition: 'center center !important',
			backgroundRepeat: 'no-repeat !important',
			'& img': {
				height: '100%',
				width: '100%',
			},
		},
		landingAction: {
			position: 'absolute',
			right: '4%',
			bottom: '4%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 1,
			[theme.breakpoints.down('sm')]: {
				right: 0,
				left: '50%',
				transform: 'translateX(-50%)',
			},
		},
		yrBox: {
			border: `3px solid ${Colors.SECONDARY}`,
			transform: 'rotate(4.52deg)',
			width: 'fit-content',
		},
		yrInfo: {
			fontFamily: Fonts.Bebas,
			fontSize: '1.5rem',
			color: Colors.SECONDARY,
			textTransform: 'uppercase',
		},
		enterCTA: {
			fontFamily: Fonts.Bebas,
			fontSize: '3.5rem',
			color: Colors.SECONDARY,
			transform: 'rotate(1.35deg)',
			textTransform: 'uppercase',
			cursor: 'pointer',
			whiteSpace: 'nowrap',
			'&:hover': {
				background:
					'linear-gradient(93.09deg, #FF00C7 -30.79%, #00FFD1 42.53%, #FFF500 118.97%)',
				WebkitBackgroundClip: 'text',
				WebkitTextFillColor: 'transparent',
				transform: 'rotate(-3.57deg)',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '2.5rem',
			},
		},
	})
);

export default function LandingPage() {
	const classes = useStyle();
	const theme = useTheme();
	const [showBlackOverlay, setShowBlackOverlay] = useState(false);
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();

	const onClickLandingCTA = () => {
		navigate('/raffle');
	};

	const getImage = () => {
		if (isMobileScreen && showBlackOverlay) {
			return mobileBleckImg;
		} else if (isMobileScreen && !showBlackOverlay) {
			return mobileBleckImg;
		} else if (!isMobileScreen && showBlackOverlay) {
			return overlayBleckImg;
		} else {
			return bleckImg;
		}
	};

	return (
		<Box
			style={{
				background: `url(${getImage()})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				backgroundRepeat: 'no-repeat',
			}}
			className={classes.landing}
		>
			{/* <img src={getImage()} alt="bleck" /> */}
			<Box className={classes.landingAction}>
				<Box className={classes.yrBox}>
					<Typography className={classes.yrInfo}>18yr+ cets only</Typography>
				</Box>
				<Typography
					onMouseEnter={() => setShowBlackOverlay(true)}
					onMouseLeave={() => setShowBlackOverlay(false)}
					onClick={onClickLandingCTA}
					className={classes.enterCTA}
				>
					entrrr bleck-market {'>'}
				</Typography>
			</Box>
		</Box>
	);
}
