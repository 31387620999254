import axios from "axios"

export const getSubmittedRaffles = async (raffleID) => {
  const url = `https://api.cetsoncreck.com/bleckmarket/raffle/details/${raffleID}/`
  const result = await axios.get(url)
  return result.data
}

export const submitUserName = async (userName, wallet, raffleId) => {
  const url = "https://api.cetsoncreck.com/bleckmarket/raffle/submit"
  await axios.post(url, {
    wallet,
    raffle_id: raffleId,
    discord_id: userName
  })
}