import {
	Box,
	Drawer,
	IconButton,
	Typography,
	createStyles,
	makeStyles,
} from '@material-ui/core';
import { Colors, Fonts } from '../../utils/styles/DefaultTheme';
import React, { useCallback, useContext, useState } from 'react';
import {
	faAngleRight,
	faBars,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { CardContext } from '../../context/cardContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeReward from '../home/HomeReward';
import { ReactComponent as MagicEden } from '../../assets/images/magic-eden.svg';
import { WalletMultiButton } from '@solana/wallet-adapter-material-ui';
import cat from '../../assets/images/cat-logo.png';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import game from '../../assets/images/game.png';
import styled from 'styled-components';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { useNavigate } from 'react-router-dom';

const useStyle = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			position: 'relative',
			alignItems: 'center',
		},
		centerContainer: {
			position: 'absolute',
			top: 0,
			left: '50%',
			transform: 'translateX(-50%)',
			whiteSpace: 'nowrap',
			display: 'contents',
		},
		logo: {
			width: '55px',
			height: '55px',
			cursor: 'pointer',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& svg': {
				color: Colors.SECONDARY,
				height: '60%',
				width: '60%',
			},
			'& img': {
				width: '55px',
				height: '55px',
				cursor: 'pointer',
			},
		},
		bar: {
			'& svg': {
				fontSize: '34px',
				color: Colors.SECONDARY,
			},
		},
		title: {
			fontFamily: Fonts.Bebas,
			color: Colors.SECONDARY,
			fontSize: '1.75rem',
			textTransform: 'uppercase',
			position: 'relative',
		},
		closeIcon: {
			position: 'absolute',
			top: '20px',
			right: '40px',
			'& svg': {
				color: Colors.SECONDARY,
				opacity: 0.6,
				fontSize: '28px',
			},
		},
		menu: {
			fontFamily: Fonts.Bebas,
			fontSize: '1.5rem',
			color: Colors.SECONDARY,
			textTransform: 'uppercase',
		},
		section: {
			position: 'relative',
			marginBottom: theme.spacing(4.3),
			paddingBottom: theme.spacing(3.5),
			'&:after': {
				position: 'absolute',
				left: 0,
				bottom: 0,
				width: '100%',
				height: '3px',
				background: Colors.SECONDARY,
				content: '""',
			},
		},
	})
);

const ConnectButton = styled(WalletMultiButton)`
	width: 100%;
	height: 67px;
	background: ${Colors.SECONDARY};
	color: ${Colors.Background} !important;
	font-family: ${Fonts.Bebas};
	font-size: 32px;
	text-transform: uppercase;
	white-space: nowrap;
	margin-bottom: 35px;

	&:hover {
		background: ${Colors.SECONDARY};
		color: ${Colors.Background} !important;
	}
`;

export default function VerticalHeader() {
	const classes = useStyle();
	const [open, setOpen] = useState(false);
	const [card, setCard] = useContext(CardContext);
	const wallet = useAnchorWallet();
	const openDrawer = useCallback(() => setOpen(true), []);
	const closeDrawer = useCallback(() => setOpen(false), []);
	const navigate = useNavigate();

	let walletAddr = null;
	if (wallet && wallet?.publicKey.toString()) {
		walletAddr = wallet?.publicKey.toString();
		walletAddr = `${walletAddr?.slice(0, 4)}...${walletAddr?.slice(-5)}`;
	}

	const onClickRaffle = () => {
		setCard(null);
		navigate('/raffle');
		closeDrawer();
	};

	// useEffect(() => {
	// 	if (!walletAddr) {
	// 		setCard(null);
	// 		navigate('/home');
	// 		closeDrawer();
	// 	}
	// }, [walletAddr, navigate, setCard, closeDrawer]);

	return (
		<Box className={classes.container}>
			<Box className={classes.logo}>
				<img src={cat} alt="cat" />
			</Box>
			<Box className={classes.centerContainer}>
				<Typography className={classes.title}>Bleck Market</Typography>
			</Box>
			<IconButton className={classes.bar} onClick={openDrawer}>
				<FontAwesomeIcon icon={faBars} />
			</IconButton>
			<Drawer open={open} anchor="left" onClose={closeDrawer}>
				<Box
					style={{
						background: Colors.Background,
						overflowY: 'scroll',
					}}
					width="100vw"
					minHeight="100vh"
					position={'relative'}
					padding={2}
					paddingTop={42}
					display="flex"
					flexDirection={'column'}
					justifyContent={'center'}
					alignItems={'center'}
					boxSizing="border-box"
				>
					<Box className={classes.section} width="100%">
						<HomeReward />
					</Box>
					<Box className={classes.section} width="100%">
						<Box
							marginBottom={9}
							display={'flex'}
							justifyContent="space-between"
							onClick={onClickRaffle}
						>
							<Typography className={classes.title}>RAFFLES</Typography>
							<Typography className={classes.title}>
								<FontAwesomeIcon icon={faAngleRight} />
							</Typography>
						</Box>
						<Box
							marginBottom={9}
							display={'flex'}
							justifyContent="space-between"
						>
							<Typography className={classes.title}>AUCTIONS</Typography>
							{/* <Typography className={classes.title}>
								<FontAwesomeIcon icon={faAngleRight} />
							</Typography> */}
						</Box>
					</Box>
					<Box className={classes.section} width="100%">
						<Box
							marginBottom={9}
							display="flex"
							justifyContent={'space-between'}
							onClick={() => {
								window.open('https://twitter.com/cetsnft');
							}}
						>
							<Typography className={classes.title}>TWITTER</Typography>
							<Typography className={classes.title}>
								<FontAwesomeIcon icon={faTwitter} />
							</Typography>
						</Box>
						<Box
							marginBottom={9}
							display="flex"
							justifyContent={'space-between'}
							onClick={() => {
								window.open('https://discord.gg/creckhouse');
							}}
						>
							<Typography className={classes.title}>DISCORD</Typography>
							<Box className={classes.logo}>
								<img src={game} alt="game" />
							</Box>
						</Box>
						<Box
							marginBottom={9}
							display="flex"
							justifyContent={'space-between'}
							onClick={() => {
								window.open('https://magiceden.io/marketplace/cets_on_creck');
							}}
						>
							<Typography className={classes.title}>Magic Eden</Typography>
							<Box className={classes.logo}>
								<MagicEden />
							</Box>
						</Box>
					</Box>
					<Box width="100%">
						<ConnectButton>
							{walletAddr ? `${walletAddr}` : 'Connect wallet'}
						</ConnectButton>
						{/* <Box width="100%" height="200px" /> */}
					</Box>
					<Box className={classes.closeIcon} onClick={closeDrawer}>
						<FontAwesomeIcon icon={faTimes} />
					</Box>
				</Box>
			</Drawer>
		</Box>
	);
}
