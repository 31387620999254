import {
	Box,
	Grid,
	Typography,
	createStyles,
	makeStyles,
} from '@material-ui/core';
import { Colors, Fonts } from '../../utils/styles/DefaultTheme';
import React, { useEffect, useMemo, useState } from 'react';

import HomeBodyCard from '../home/HomeBodyCard';
import { Raffle } from '../../lib/types';
import { useRafflesStore } from '../../hooks/useRafflesStore';
import { useWallet } from '@solana/wallet-adapter-react';

const useStyle = makeStyles((theme) =>
	createStyles({
		bodyContainer: {
			width: '100%',
			marginTop: theme.spacing(2.5),
		},
	})
);

interface HomeBodyContainerProps {}

export interface tableType {
	winner: string;
	entries: number;
	claim: JSX.Element | null;
}

export default function HomeBodyContainer(props: HomeBodyContainerProps) {
	const { publicKey } = useWallet();
	const classes = useStyle();
	const { raffles, fetchAllRaffles, fetching } = useRafflesStore();
	const [showOwnRafflesOnly, setShowOwnRafflesOnly] = useState(false);
	const [hideEndedRaffles, setHideEndedRaffles] = useState(false);

	const filterMap = useMemo(
		() => ({
			own: (raffle: Raffle) => raffle.entrants.has(publicKey?.toString() || ''),
			ongoing: (raffle: Raffle) => new Date() < raffle.endTimestamp,
		}),
		[publicKey]
	);

	const rafflesToShow = useMemo(() => {
		//@ts-ignore
		let toShow = [...raffles.values()].sort(
			(raffle1, raffle2) =>
				raffle2.endTimestamp.getTime() - raffle1.endTimestamp.getTime()
		);
		if (showOwnRafflesOnly) toShow = toShow.filter(filterMap.own);
		if (hideEndedRaffles) toShow = toShow.filter(filterMap.ongoing);
		return toShow;
	}, [raffles, filterMap, showOwnRafflesOnly, hideEndedRaffles]);

	const getView = () => {
		if (rafflesToShow.length > 0) {
			return (
				<Grid container spacing={2}>
					{rafflesToShow.map((card, i) => {
						const modifiedCard = {
							image: card.metadata.overviewImageUri,
							title: card.metadata.name,
							entries: card.totalTickets,
							numUniqueWallets: card.entrants.size,
							winner: card.prizes.length,
							time: card.endTimestamp?.toString(),
							ticketPrice:
								card.proceeds.ticketPrice.toNumber() /
								10 ** (card.proceeds.mint.decimals || 0),
							raffleId: card.publicKey.toBase58(),
							raffle: card,
							description: card.metadata.description ?? '',
							total: card.metadata.total ?? 1,
						};
						return <HomeBodyCard key={i} {...modifiedCard} />;
					})}
				</Grid>
			);
		} else {
			return (
				<Box
					width="100%"
					height={'400px'}
					display="flex"
					flexDirection={'column'}
					justifyContent={'center'}
					alignItems="center"
				>
					<div className="lds-facebook">
						<div></div>
						<div></div>
						<div></div>
					</div>
					<Typography
						style={{
							fontFamily: Fonts.Bebas,
							fontSize: '1.5rem',
							color: Colors.SECONDARY,
						}}
					>
						B PATIENT PESSIES!
					</Typography>
				</Box>
			);
		}
	};

	useEffect(fetchAllRaffles, [fetchAllRaffles]);

	return <Box className={classes.bodyContainer}>{getView()}</Box>;
}
