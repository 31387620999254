import { RaffleMetaData } from '../lib/types';
import { TESTING } from './misc';

// HbwUf4Lhc5a7QEdeGxBR7N9ZgHrokajoeQvsvL9ZND12 - can be used later
const testWhitelist = new Map<string, RaffleMetaData>([
	[
		'2TeQjnQJKvt9pBD1RP4YAZSt1MFbA2JGj59Paoi1Tjx1',
		{
			name: '313 Laces by CETMAN',
			overviewImageUri:
				'https://media.discordapp.net/attachments/1054513124776087613/1090697351028551814/bleckmarket_laces.png?width=1227&height=1227',
			twitter: 'https://twitter.com/Cetcave',
			discord: 'https://discord.gg/creckhouse',
			description: '15 winners will receive 1 pair of 313 Laces by CETMAN',
			total: 5000,
			claimable: false,
			timer: 'Sunday, 02 April 2023 02:00:00 PM GMT+05:30',
		},
	],
	[
		'2YAyYyf43Uhb3Duerdrd7NNy6UcVLYKwvjTWbWTYDEP9',
		{
			name: 'Solcasino.io Whitelist',
			overviewImageUri:
				'https://pbs.twimg.com/media/FqFULLKWcAINlwI?format=png&name=medium',
			twitter: 'https://twitter.com/Solcasinoio',
			discord: '',
			description:
				'15 winners will receive 1 WL spot for Solcasino.io upcoming NFT project ',
			total: 5000,
			claimable: false,
			timer: 'Sunday, 26 March 2023 07:00:00 PM GMT',
		},
	],
	[
		'ANfBaesfDSp8FXY18WHbKtSPiDRYDcMACgb2T8p9ukZw',
		{
			name: 'Cet #2136',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/coc_small/2135.jpg',
			twitter: 'https://twitter.com/CetsOnCreck',
			discord: 'https://discord.gg/creckhouse',
			description:
				'Cets on Creck is raffling 4 cets from the treasury to raise funds as a community for Turkey victims. Take part to contribute & spread lob.',
			total: 2000,
			claimable: true,
			timer: 'Tuesday, 07 January 2023 10:30:00 PM GMT+05:30',
		},
	],
	[
		'CBfQrPE8S7bsuimDGdFk7KUcWRtJaD76KDdiNELXZe2Z',
		{
			name: 'Cet #1754',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/coc_small/1753.jpg',
			twitter: 'https://twitter.com/CetsOnCreck',
			discord: 'https://discord.gg/creckhouse',
			description:
				'Cets on Creck is raffling 4 cets from the treasury to raise funds as a community for Turkey victims. Take part to contribute & spread lob.',
			total: 2000,
			claimable: true,
			timer: 'Tuesday, 07 January 2023 10:30:00 PM GMT+05:30',
		},
	],
	[
		'4DdWD2TdfhqzSkWw4btJSWSZniFrx5fbzzV1zXVEiFe2',
		{
			name: 'Cet #2000',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/coc_small/1999.jpg',
			twitter: 'https://twitter.com/CetsOnCreck',
			discord: 'https://discord.gg/creckhouse',
			description:
				'Cets on Creck is raffling 4 cets from the treasury to raise funds as a community for Turkey victims. Take part to contribute & spread lob.',
			total: 2000,
			claimable: true,
			timer: 'Tuesday, 07 January 2023 10:30:00 PM GMT+05:30',
		},
	],
	[
		'BEuSefdHFg5yWwopJ4v2ZTkvWxYyGtkFSV5mbswSPV9N',
		{
			name: 'Cet #5974',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/coc_small/5973.jpg',
			twitter: 'https://twitter.com/CetsOnCreck',
			discord: 'https://discord.gg/creckhouse',
			description:
				'Cets on Creck is raffling 4 cets from the treasury to raise funds as a community for Turkey victims. Take part to contribute & spread lob.',
			total: 2000,
			claimable: true,
			timer: 'Tuesday, 07 January 2023 10:30:00 PM GMT+05:30',
		},
	],
	[
		'GsvqCRpXcV8bRSyeCsmuD2NesKxX14QPoDnnnK8hb1qc',
		{
			name: 'Mutual Friends Whitelist',
			overviewImageUri:
				'https://pbs.twimg.com/media/FnbADO4XwAIgQLe?format=jpg&name=small',
			twitter: 'https://twitter.com/mutualfriendsio',
			discord: 'https://discord.gg/mutualfriends',
			description:
				'50 winners will receive 1 WL spot for Mutual Friends upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 29 January 2023 10:30:00 PM GMT+05:30',
		},
	],
	[
		'3tmeD4ibQu4yBHCWp9pfLi2FHHZ8Z7bDPdMQscETjijk',
		{
			name: 'Knittables Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/knittables.jpeg',
			twitter: 'https://twitter.com/theKnittables',
			discord: 'https://discord.gg/knittables',
			description:
				'40 winners will receive 1 WL spot for Knittables upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 06 December 2022 10:30:00 PM GMT+05:30',
		},
	],
	[
		'3bpGkCi1AU5dJcew14Kg1RJaMBGZqtB5a8BRcBYpTWRg',
		{
			name: 'Breadheads Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/breadheads.png',
			twitter: 'https://twitter.com/breadheadnfts',
			discord: 'https://discord.gg/breadheads',
			description:
				'25 winners will receive 1 WL spot for Breadheads upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 23 October 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'D3QS4t66o5KnQd3stB4GH1oQbsnW6M2vLbWrThMYphxe',
		{
			name: 'CryptoTitans Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/cryptotitans.jpeg',
			twitter: 'https://twitter.com/OfficialCTitans',
			discord: 'https://discord.gg/cryptotitans',
			description:
				'30 winners will receive 1 WL spot for CryptoTitans upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 19 October 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'Dp8a7zkVB276J2Yc4MnSGGt3UfZL9EY4qUWWhR8B38md',
		{
			name: 'Songen Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/songen.jpeg',
			twitter: 'https://twitter.com/SongenArt',
			discord: 'https://discord.gg/songen',
			description:
				'40 winners will receive 1 WL spot for Songen upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 15 October 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'7nQaZ2t86p3eRVLhneE9vgxuoTkKq1aTvpinS9Vq7YNH',
		{
			name: 'Underground Society',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/underground_society.jpeg',
			twitter: 'https://twitter.com/UGS_Labs',
			discord: 'https://discord.com/invite/undergroundsociety',
			description:
				'30 winners will receive 1 WL spot for Underground Society upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 06 October 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'96MC3upWBrJbHRSY9gkp5e198iHFfrsafNoR74YnfENb',
		{
			name: 'WeBall',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/weball.jpeg',
			twitter: 'https://twitter.com/WeBallLottery',
			discord: 'https://discord.com/invite/weball',
			description:
				'10 winners will receive 1 VIP WL (guaranteed mint) spot for WeBall upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 06 October 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'F1DRmYV8CiTwhmzSctN9hN8iCBbMfvtoY42YRzY6KrqU',
		{
			name: 'Stellar Drifters Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/stellar.jpeg',
			twitter: 'https://twitter.com/StellarDrifters',
			discord: 'https://discord.com/invite/stellardrifters',
			description:
				'40 winners will receive 1 WL spot for Stellar Drifters upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 05 October 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'6kvF2YXAr6w4NQyyzL358KCAvRKYpXcjNP4VFV7wdDuf',
		{
			name: 'Skellified Cets NFT',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/skelliedcet.jpeg',
			twitter: 'https://twitter.com/goodskellas',
			discord: 'https://discord.gg/VPCfrRU4xn',
			description:
				"Good Skellas team is working on a new Skellified drop which consists in manually skellifying any NFT to create a 1/1 derivative of it. They're offering to 5 Cets holders the opportunity to skellifiy their forever Cet into a custom hand made 1/1 new NFT (skellification process may not be suited for every Cets traits).",
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 26 September 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'4cJ7SZA9uuvKsSN6vXK7EgRo5XiDW6YMfWEUiubq4hLz',
		{
			name: 'Inwork Labs Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/inwork.jpeg',
			twitter: 'https://twitter.com/InkworkLabs',
			discord: 'https://t.co/opNF7aM5B6',
			description:
				'40 winners will receive 1 WL spot for Inwork Labs upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 23 September 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'8SLNCbqvAEor7Unh2EE7YYMfVL2oH9n5dyY4WVMaKJCB',
		{
			name: 'Knittables Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/knittables.jpeg',
			twitter: 'https://twitter.com/theKnittables',
			discord: 'https://discord.com/invite/mjnuablosvtrgs',
			description:
				'40 winners will receive 1 WL spot for Knittables upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 23 September 2022 06:30:00 AM GMT+05:30',
		},
	],
	[
		'7BYrkGwhJAdjYjtf9YwXL2PbwvLmsRkT97rRVNk4zfhs',
		{
			name: 'Lunar Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/lunar.png',
			twitter: 'https://twitter.com/lunarprojectnft',
			discord: 'https:/discord.gg/lunarnft',
			description:
				'40 winners will receive 1 WL spot for Lunar upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 19 September 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'9NsNFsgnNNgK5kMQbqPvYQU8aQHeHSfgTtuXZAwJBew8',
		{
			name: 'Rifters Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/rifters.jpeg',
			twitter: 'https://twitter.com/riftersio',
			discord: 'https://discord.gg/c1g',
			description:
				"40 winners will receive 'Sanctuary Deathlist' role which grants 1 guaranteed mint for Rifters upcoming NFT project",
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 17 September 2022 06:30:00 AM GMT+05:30',
		},
	],
	[
		'4ehYHr3kAEAGJfeK6ReSY7TYS7A96FX7u5gcrwemTRZh',
		{
			name: 'SolEddaRity Free Mint',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/soleddarity.png',
			twitter: 'https://twitter.com/SolEddaRity',
			discord: 'https://discord.gg/soleddarity',
			description:
				'5 winners will receive 1 Free Mint for SolEddaRity upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 16 September 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'64hoy7new7gPFFPf3cJiazXyu9JmxymGpk7jYG3BEkLz',
		{
			name: 'CRIPCO Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/cripco.png',
			twitter: 'https://twitter.com/cripco_official',
			discord: 'https://t.co/VpdSgt2P2L',
			description:
				'20 winners will receive 1 WL spot for CRIPCO upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 13 September 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'7XJ2Cijk8Xg1oXPocvaHoiQPhortn82ic9dah8YsNtwA',
		{
			name: 'Coinfra Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/coinfra.jpeg',
			twitter: 'https://twitter.com/gocoinfra',
			discord: 'https://discord.gg/psKdEkcmkU',
			description:
				'50 winners will receive 1 WL spot for Coinfra upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 10 September 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'FS8298Uzs7e3ZAKnrbCc4BxhYKCYsnFbqVeoVQeJo2F5',
		{
			name: 'Walled Off Hotel Box Set',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/walled_off.jpeg',
			twitter: 'https://twitter.com/LCDLabNFT',
			discord: 'https://discord.gg/lcdlab',
			description:
				'1 winner will receive 1 Walled Off Hotel Box Set (Banksy IRL piece of art) ',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 10 September 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'CzGXHvhHKH3UgUH6iRwxtgCmZFUXjfH3cF6Chv7n68Ns',
		{
			name: 'Satori Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/satori.png',
			twitter: 'https://twitter.com/NFT_Satori',
			discord: 'http://discord.gg/besatori',
			description:
				'50 winners will receive 1 WL spot for Satori upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 3 September 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'EdZpujaQi9skqbe4ZsUExyG6LYcaPFh3bDug89XjrqdH',
		{
			name: 'Crowdsurfers (phase 2) Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/crowdsurders_p2.png',
			twitter: 'https://twitter.com/CrowdsurfNFT',
			discord: 'https://discord.gg/crowdsurf',
			description:
				'50 winners will receive 1 WL spot for Crowdsurfers (phase 2) upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 2 September 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'FHvF7KtSys75n72nBNfGCevJaLjwpEV62F4eGFgwkTrs',
		{
			name: '9 Lives Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/9lives.jpeg',
			twitter: 'https://twitter.com/sol9lives',
			discord: 'https://discord.gg/9Lives',
			description:
				' 20 winners will receive 1 WL spot for 9 Lives upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 30 August 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'2pAKkFzYCKAGoLqBKBPAUBMByJicNch29UdYfRE1H5NJ',
		{
			name: 'SharkyFi Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/sharkyfi.png',
			twitter: 'https://twitter.com/sharkyfi',
			discord: 'https://discord.gg/jDMBfqzRvP',
			description:
				'40 winners will receive 1 WL spot for SharkyFi upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 18 August 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'HcxSwzQQsq2LMbxBGgqDnBQaSh2arAP6A1U3dwpXp47d',
		{
			name: 'Custom Cets Sneakers by Mr. Simply',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/jordans.png',
			twitter: 'https://twitter.com/MrSimplyCustom',
			discord: 'https://discord.gg/creckhouse',
			description:
				'2 winners will receive custom Cets sneakers by Mr. Simply. Winners must be holding at least 1 Cet (which will be painted on the sneakers).',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 13 August 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'AW8sp31jXAk7uYZZxNBMaQTdAXZ7Q6s2JWFfXAPc6avH',
		{
			name: 'Azra Games Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/azra_games.jpg',
			twitter: 'https://twitter.com/AzraGames',
			discord: 'http://discord.gg/azra',
			description:
				'40 winners will receive 1 WL spot for Azra Games upcoming NFT project. Please keep in mind that Azra Games NFT will be minted on ETH.',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 10 August 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'piNRkLnRVSx8wHK8hQna5Q23HopUFSKQqz33MrXGENn',
		{
			name: 'SOLSWIPE Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/solswipe.jpg',
			twitter: 'https://twitter.com/solswipecard',
			discord: 'https://discord.gg/solswipe',
			description:
				'40 winners will receive 1 WL spot for SOLSWIPE upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 2 July 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'3vhxt53NNdybvxoReS26y3h7NCs6cjdswDfshSaDojp7',
		{
			name: '404 Arcane Valley Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/arcane.jpeg',
			twitter: 'https://twitter.com/ArcaneValleyNFT',
			discord: 'http://discord.gg/404arcanevalley',
			description:
				'75 winners will receive 1 WL spot for 404 Arcane Valley upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 26 July 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'AkCgJcTpEpD1rAmL6CvetCtNUGxHJm4WcAUFv65cTVWW',
		{
			name: 'Rakkudos Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/rakkudos.jpeg',
			twitter: 'https://twitter.com/rakkudos',
			discord: 'http://discord.gg/shakudo',
			description:
				'40 winners will receive 1 WL spot for Rakkudos upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 20 July 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'3Do8dTArPzCzVkqWRz8HzFfH9CLJzJ9wi7pgU3FxgbTz',
		{
			name: 'Degen Fat Cat Whitelist Box',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/degen_fat_cat.jpeg',
			twitter: 'https://twitter.com/degenfatcats',
			discord: 'https://discord.gg/degenfatcats',
			description:
				"40 winners will receive 1 Degen Fat Cat Whitelist Box. A Whitelist Box isn't guaranteeing a WL spot. If you win a box, you'll have to open it and will have a chance to get a WL Key (which grants guaranteed WL). Only one Whitelist Box can be won per wallet, so please consider using multiple wallets if you're planning to buy lots of tickets.",
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 16 July 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'23SpijsMUJBxcZhMbx1uPy3uhSidgDBWjtDc5ooNTecm',
		{
			name: 'Crowdsurf Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/crowdsurf.png',
			twitter: 'https://twitter.com/CrowdsurfNFT',
			discord: 'https://discord.gg/crowdsurf',
			description:
				'40 winners will receive 1 WL spot for Crowdsurf upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 14 July 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'5PdvZbrM5ZRrYvGKQTUiyKk2FiHLjsc1tcMtvmiKUZhM',
		{
			name: 'Descendants Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/descendants.png',
			twitter: 'https://twitter.com/AncestorNFT',
			discord: 'https://discord.gg/RxwThnJCzB',
			description:
				'40 winners will receive 1 WL spot for Descendants upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 10 July 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'F8xQWzJr9BHihv9J7rdbawo9ZCoNNmqGKREWEEhAagSu',
		{
			name: 'RadCats Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/radcats.jpeg',
			twitter: 'https://twitter.com/RadCatsNFT',
			discord: 'https://discord.com/creckhouse',
			description:
				'40 winners will receive 1 WL spot for RadCats upcoming NFT project (Discord Closed)',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 08 July 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'3eSWPV2tbPzn7Mhw2piLyDGAMQRPr4TMsrfuUzRouPrC',
		{
			name: 'PENGSOL Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/pengsol_wl.png',
			twitter: 'https://twitter.com/pengsol_',
			discord: 'https://discord.com/creckhouse',
			description:
				'25 winners will receive 1 WL spot for PENGSOL upcoming NFT project (Discord Closed)',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 11 June 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'NvtQ4Ss7QPrPE586kYheVWTiQVRBkkSAVvET4iJ6ENo',
		{
			name: 'Neo Hunters Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/neo_hunters.jpg',
			twitter: 'https://twitter.com/neo_hunters',
			discord: 'http://discord.gg/neohunters',
			description:
				'20 winners will receive 1 WL spot for Neo Hunters upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 10 June 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'6YBzEYCyBfEioY1wUnX4UcFuGXGZEKgjCeDyvbo7voua',
		{
			name: 'Degen Sweepers Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/degen_sweepers.png',
			twitter: 'https://twitter.com/DegenSweepers',
			discord: 'https://discord.gg/degensweepers',
			description:
				'100 winners will receive 1 WL spot for Degen Sweepers upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Tuesday, 7 June 2022 06:30:00 PM GMT+05:30',
		},
	],
	[
		'HKoxopgKLNzBsjAEqA5pmuWDVNjALNPb6gHdbnFowvck',
		{
			name: 'Saint Skully Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/skeleton.png',
			twitter: 'https://twitter.com/SaintSkullyNFT',
			discord: 'https://discord.gg/saintskully',
			description:
				'40 winners will receive 1 WL spot for Saint Skully upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Sunday, 5 June 2022 06:30:00 AM GMT+05:30',
		},
	],
	[
		'5RHJ44A8y5xmgnCFG4UhSrMRA3aN988bDi5UPf3nD4J5',
		{
			name: 'PRIMATES Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/primates.png',
			twitter: 'https://twitter.com/Primatesnft',
			discord: 'https://discord.gg/theprimates',
			description:
				'20 winners will receive 1 WL spot for PRIMATES upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Wednesday, 1 June 2022 6:30:00 PM GMT+05:30',
		},
	],
	[
		'2jBuTQPyaB2NnKjFrHaFgHo1g9xw5srfvFQsNdwrgALJ',
		{
			name: 'Bubblegoose Ballers Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/bubblegoose.jpeg',
			twitter: 'https://twitter.com/BubblegooseXYZ',
			discord: 'https://www.bubblegooseballers.xyz/',
			description:
				'40 winners will receive 1 WL spot for Bubblegoose Ballers upcoming NFT project',
			total: 5000,
			claimable: false,
			timer: 'Saturday, 23 April 2022 4:00:00 PM GMT+05:30',
		},
	],
	[
		'GDWJbxqvsoJxupSgFSqY6UszFFSu3dteBh2pTnLPhF6H',
		{
			name: 'Shrouded Playground Whitelist',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/shrouded.jpeg',
			twitter: 'https://mobile.twitter.com/ShroudedNFT',
			discord: 'https://discord.com/invite/dMdaUBgG2Q',
			description:
				'Winners will receive Shrouded Playground Whitelist! Good luck cets!',
			total: 5000,
			claimable: false,
			timer: 'Saturday, 23 April 2022 4:00:00 PM GMT+05:30',
		},
	],
	[
		'9B8W3K9Vq1gUkqwxTVHPWWhxx5iJPkFQKVp7VEpTZTSX',
		{
			name: '#CetsCare fundraiser',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/cetscharity.jpg',
			twitter: 'https://twitter.com/CetsOnCreck',
			discord: 'https://discord.gg/creckhouse',
			description: 'Winners will receive 2 Okay bears and 1 Cet',
			total: 5000,
			claimable: true,
		},
	],
	[
		'9Ws4Bhi7wzA2TuWm8LC94M1wkgxF4YvcEtcqXAx3ozT1',
		{
			name: 'Communi3: Mad Scientists',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/communi3_raffle.jpeg',
			twitter: 'https://twitter.com/communi3_io',
			discord: 'http://discord.gg/communi3',
			description:
				'Winners will receive Communi3 Mad Scientists! Good luck cets!',
			total: 5000,
			claimable: true,
		},
	],
	[
		'7kCwJykmX15Jpq9DKVuVG1Ms8ddqiHnTLXDm4pyEV7CK',
		{
			name: 'Mindfolk Pirates',
			overviewImageUri:
				'https://cetsoncreck.s3.amazonaws.com/bleckmarket/raffle/mindfolk_pirates.png',
			twitter: 'https://twitter.com/mindfolkART',
			discord: 'https://discord.com/invite/zsMxCZxYKw',
			description: 'Winners will receive Mindfolk Pirates! Good luck cets!',
			total: 5000,
			claimable: true,
		},
	],
	// [
	// 	'DppYVf41euGX1x6oDXB88gMKqVLN27gwJ1sZVVg8jui2',
	// 	{
	// 		name: 'WL Test',
	// 		overviewImageUri: 'https://metadata.degods.com/g/8351.png',
	// 		twitter: 'https://twitter.com/degodsnft',
	// 		discord: 'http://discord.gg/dedao',
	// 		description: 'Winner will receive this DeGod! Good luck cets!',
	// 		total: 10,
	// 		claimable: false,
	// 		timer: 'Saturday, 23 April 2022 4:00:00 PM GMT+05:30',
	// 	},
	// ],
	[
		'8P5beh4LsoURPvHCEtPyKQTv6iRLj9tBPafgPJNqcwfg',
		{
			name: 'DeGod #8359',
			overviewImageUri: 'https://metadata.degods.com/g/8358.png',
			twitter: 'https://twitter.com/degodsnft',
			discord: 'http://discord.gg/dedao',
			description: 'Winner will receive this DeGod! Good luck cets!',
			total: 5000,
			claimable: true,
		},
	],
	[
		'D1DuGDwu451kUPqMBxPVqUy1pVV3YoQQkhofpfK9CeCD',
		{
			name: 'SMB #4737',
			overviewImageUri:
				'https://arweave.net/7En-exGeDtQ4yElS5kZCyuOrkpb5ieg7uwH60uwhfPM',
			twitter: 'https://twitter.com/SolanaMBS',
			discord: 'http://discord.gg/solanamonkey',
			description: 'Winner will receive this SMB! Good luck cets!',
			total: 5000,
			claimable: true,
		},
	],
	[
		'CPkyym9DAPfXMv8jkLe7dddWHQC3wV6p21mztUYAaDfg',
		{
			name: 'Test Raffle #1',
			overviewImageUri:
				'https://arweave.net/7En-exGeDtQ4yElS5kZCyuOrkpb5ieg7uwH60uwhfPM',
			twitter: 'https://twitter.com/SolanaMBS',
			discord: 'http://discord.gg/solanamonkey',
			description: 'Winner will receive this SMB! Good luck cets!',
			total: 5000,
			claimable: true,
		},
	],
	[
		'BKjkq163EDWLhh5mSAeaVsGMQWj315HLVB7LFPeNFJWe',
		{
			name: 'Test Raffle #2',
			overviewImageUri:
				'https://arweave.net/7En-exGeDtQ4yElS5kZCyuOrkpb5ieg7uwH60uwhfPM',
			twitter: 'https://twitter.com/SolanaMBS',
			discord: 'http://discord.gg/solanamonkey',
			description: 'Winner will receive this SMB! Good luck cets!',
			total: 5000,
			claimable: true,
		},
	],
	[
		'H32nTRRyLvsSbEh4up57vfNeLkS2tveGNdfBZ6LVCY1L',
		{
			name: 'Test Raffle USDC #1',
			overviewImageUri: 'https://metadata.degods.com/g/8358.png',
			twitter: 'https://twitter.com/degodsnft',
			discord: 'http://discord.gg/dedao',
			description: 'Winner will receive this DeGod! Good luck cets!',
			total: 5000,
			claimable: true,
		},
	],

	// mainnet test mods
	// [
	// 	'ATUYgxo8uey9BwTCmZYTjWwHnLjw9xE7FmSpLnjpQAFc',
	// 	{
	// 		name: 'OG WL',
	// 		overviewImageUri:
	// 			'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CoCrfJfccDLXxdd4HSVynLGKavvBPPHjJEGphwXZTFyn/logo.png',
	// 		twitter: 'https://twitter.com/CetsOnCreck',
	// 		discord: 'https://discord.com/invite/creckhouse',
	// 		description: 'OG WL Token',
	// 		total: 50,
	// 	},
	// ],
	// [
	// 	'2MgT5dRjT3jL4aBMip4kXskyDQpURAg5kZA7f36bZzfg',
	// 	{
	// 		name: 'Pessies #9',
	// 		overviewImageUri:
	// 			'https://www.arweave.net/6KpoAWbDz0iVJlTkBlqcQvyA9AEZbNSpgCDrOwpYdRk?ext=png',
	// 		twitter: 'https://twitter.com/CetsOnCreck',
	// 		discord: 'https://discord.com/invite/creckhouse',
	// 		description: 'Get then Pessies',
	// 		total: 100,
	// 	},
	// ],
	// [
	// 	'4PiYPhqn62WGhDSHJzHAC5HtBAQ4JfhtwBUX3zdEfX1c',
	// 	{
	// 		name: 'Pessies #4',
	// 		overviewImageUri:
	// 			'https://www.arweave.net/BiDVJxaGBEktY6Mb5QFfxusEqOYCl4qRzXAQOjStSCQ?ext=png',
	// 		twitter: 'https://twitter.com/CetsOnCreck',
	// 		discord: 'https://discord.com/invite/creckhouse',
	// 		description: 'Get then Pessies',
	// 		total: 100,
	// 	},
	// ],
	// mainnet test 1
	// [
	// 	'BBdmRmBNhagy27PTAYidssXsBysZsVbp6eqa4TLozuoP',
	// 	{
	// 		name: 'Pessies #4',
	// 		overviewImageUri:
	// 			'https://www.arweave.net/4zza871IiS0VpVm0VsvNKjsjo_Ex2vAPZpPmyLIJz28?ext=png',
	// 		twitter: 'https://twitter.com/CetsOnCreck',
	// 		discord: 'https://discord.com/invite/creckhouse',
	// 		description: 'This is a test pessie',
	// 	},
	// ],
	// [
	// 	'EenFZr15GmX5x3BAcDWF46THYja4Usq3XARR8RUhLUtZ',
	// 	{
	// 		name: 'Pessies #1',
	// 		overviewImageUri:
	// 			'https://www.arweave.net/fGhluePTy2dg6E_d0ai3pejE4Znt04sSXptl8PbS96Q?ext=png',
	// 		twitter: 'https://twitter.com/CetsOnCreck',
	// 		discord: 'https://discord.com/invite/creckhouse',
	// 		description: 'This is another test pessie',
	// 	},
	// ],
	// [
	// 	'DnmfNqH5ZDiV7Gd6BzcPk6CXhqY9cYvmVkFY2gemQZHT',
	// 	{
	// 		name: 'Pessies #3',
	// 		overviewImageUri:
	// 			'https://www.arweave.net/LZSNUAaDaucDfh5TFeVAMGfm9xnkq6g5Ika4kEFE9hw?ext=png',
	// 		twitter: 'https://twitter.com/CetsOnCreck',
	// 		discord: 'https://discord.com/invite/creckhouse',
	// 	},
	// ],
	[
		'3FC4i5AqnAnY8wymjkoxWscKbUVsBvegmDSRnSdLzLuK',
		{
			name: 'dRafflenet #1',
			overviewImageUri:
				'https://ik.imagekit.io/cmjffa5th3u/default-image.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648752044409',
			twitter: 'https://twitter.com/CetsOnCreck',
			discord: 'https://discord.com/invite/creckhouse',
		},
	],
	[
		'B7ZJ8z1ch37CjjJn838xqKcp7ooAoUmtr4STN9HT6iot',
		{
			name: 'Raffle #2',
			overviewImageUri:
				'https://ik.imagekit.io/cmjffa5th3u/default-image.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648752044409',
			twitter: 'https://twitter.com/CetsOnCreck',
			discord: 'https://discord.com/invite/creckhouse',
		},
	],
	[
		'7xHAJK2YfL9dPG4FTKau297c2Dcxiz6WAZTn2WNXzF3t',
		{
			name: 'Raffle #3',
			overviewImageUri:
				'https://ik.imagekit.io/cmjffa5th3u/default-image.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648752044409',
		},
	],
	[
		'EdmGrziqDSzSdyUgVRkQL5M48RgrdByazD4fDQix66As',
		{
			name: 'Raffle #3 | SMB - 3',
			overviewImageUri:
				'https://ik.imagekit.io/cmjffa5th3u/smb3_GE0vhGPQK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649131783173',
		},
	],
	[
		'CKe4w2VvJbTGVbWppMHWDGubyA1gihC9gEhQWh88sK6A',
		{
			name: 'Raffle #4 | SMB - 32',
			overviewImageUri:
				'https://ik.imagekit.io/cmjffa5th3u/smb32_NcJ8qpdIo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649135487141',
		},
	],
]);

const prodWhitelist = new Map<string, RaffleMetaData>([
	[
		'FnHwnXGBz7NRZEsT8u12pE2cxURt8mYHQZykzmRtjb27',
		{
			name: 'dRaffle launch raffle',
			overviewImageUri: '/resources/001-mainnet-launch.gif',
		},
	],
	[
		'2QjkshNu3mrcCnriekTpppa3PFwnAR9Yf7v5vc54m2Yh',
		{
			name: 'First SOL raffle',
			overviewImageUri: '/resources/solana-logo.gif',
		},
	],
	[
		'8aEm1MoDqkYT5vCB21jC6aMMcMbdQJgmHpyBbtHDfUjU',
		{
			name: 'Anti Artist Club',
			overviewImageUri: '/resources/aartist-raffle-overview.gif',
		},
	],
	[
		'2ziwAj4awgvNyn8ywwjkBRkBsmv259u9vVyEdrGYTb54',
		{
			name: 'More SOL',
			overviewImageUri: '/resources/solana-logo.gif',
		},
	],
	[
		'EgHys3WPcM5WRpKqVHs1REfK6Npzq9sJ7dZPFPzQy2xG',
		{
			name: 'Triple SOL',
			overviewImageUri: '/resources/solana-logo-x3.gif',
		},
	],
	[
		'CjzFZfrMW4D1jZVm5upCobRi96UYnQTk5cescSt12rhV',
		{
			name: 'SAMO raffle',
			overviewImageUri: '/resources/samo-x3.gif',
		},
	],
	[
		'EZtBKgWq66KT4jRKtd4VT3LWh3mVC4pwcCsqLzKas63G',
		{
			name: 'BitBoat raffle',
			overviewImageUri: '/resources/bitboat-raffle.gif',
		},
	],
]);

export const RAFFLES_WHITELIST = TESTING ? testWhitelist : prodWhitelist;
