import {
	Box,
	IconButton,
	Typography,
	createStyles,
	makeStyles,
} from '@material-ui/core';
import { Colors, Fonts } from '../../utils/styles/DefaultTheme';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as MagicEden } from '../../assets/images/magic-eden.svg';
import React from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-material-ui';
import cat from '../../assets/images/cat-logo.png';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import game from '../../assets/images/game.png';
import styled from 'styled-components';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { useNavigate } from 'react-router-dom';

const useStyle = makeStyles((theme) =>
	createStyles({
		container: {
			width: '100%',
			// border: "1px solid yellow",
			display: 'flex',
			justifyContent: 'space-between',
			position: 'relative',
		},
		centerContainer: {
			position: 'absolute',
			top: 0,
			left: '45%',
			height: '100%',
			transform: 'translateX(-50%)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		tabTitle: {
			fontFamily: Fonts.Bebas,
			fontSize: '1.75rem',
			color: Colors.SECONDARY,
			whiteSpace: 'nowrap',
			textTransform: 'uppercase',
			marginRight: theme.spacing(5),
			position: 'relative',
			paddingBottom: theme.spacing(1),
			cursor: 'pointer',
		},
		tabTitleWithUnderline: {
			'&:after': {
				content: '""',
				height: '4px',
				background: Colors.SECONDARY,
				width: '100%',
				left: 0,
				bottom: 0,
				position: 'absolute',
			},
		},
		centerTitle: {
			fontFamily: Fonts.Bebas,
			fontSize: '3rem',
			color: Colors.SECONDARY,
			whiteSpace: 'nowrap',
			textTransform: 'uppercase',
			position: 'relative',
			marginLeft: theme.spacing(1),
		},
		logoContainer: {
			background: Colors.Background,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
		},
		icon: {
			width: '53px',
			height: '53px',
			cursor: 'pointer',
			'& img': {
				width: '53px',
				height: '53px',
				cursor: 'pointer',
			},
			'& svg': {
				width: '100%',
				height: '100%',
				fontSize: '55px',
				color: Colors.SECONDARY,
			},
		},
		title: {
			fontFamily: Fonts.Bebas,
			color: Colors.SECONDARY,
			whiteSpace: 'nowrap',
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			'& > *': {
				marginRight: theme.spacing(2.5),
			},
		},
	})
);

const ConnectButton = styled(WalletMultiButton)`
	width: 155px;
	height: 50px;
	background: ${Colors.SECONDARY};
	color: ${Colors.Background} !important;
	font-family: ${Fonts.Bebas};
	font-size: 24px;
	text-transform: uppercase;
	white-space: nowrap;
	border-radius: 0px;
	margin-right: 0px !important;

	&:hover {
		background: ${Colors.SECONDARY};
		color: ${Colors.Background} !important;
	}
`;

export default function Header() {
	const classes = useStyle();
	const wallet = useAnchorWallet();
	let walletAddr = null;
	const navigate = useNavigate();
	if (wallet && wallet?.publicKey.toString()) {
		walletAddr = wallet?.publicKey.toString();
		walletAddr = `${walletAddr?.slice(0, 4)}...${walletAddr?.slice(-5)}`;
	}

	const onClickLogo = () => {
		navigate('/raffle');
	};

	// useEffect(() => {
	// 	if (!walletAddr) {
	// 		navigate('/home');
	// 	}
	// }, [walletAddr, navigate]);

	return (
		<Box className={classes.container}>
			<Box onClick={onClickLogo} className={classes.logoContainer}>
				<IconButton className={classes.icon}>
					<img src={cat} alt="cat" />
				</IconButton>

				<Typography className={classes.centerTitle}>Bleck-market </Typography>
			</Box>

			<Box className={classes.centerContainer}>
				<Typography
					className={`${classes.tabTitle} ${classes.tabTitleWithUnderline}`}
				>
					Raffles{' '}
				</Typography>
				<Typography
					style={{ marginRight: 0 }}
					className={classes.tabTitle}
					onClick={() => {
						window.open('https://coctions.cetsoncreck.com/');
					}}
				>
					Auctions
				</Typography>
				{/* <span
					style={{
						position: 'absolute',
						fontFamily: Fonts.Cedarville,
						fontSize: '2rem',
						background:
							'linear-gradient(87.22deg, #FF00C7 5.62%, #00FFD1 50.96%, #FFF500 98.22%)',
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
						textAlign: 'center',
						transform: 'rotate(-28.85deg)',
						bottom: '-58%',
						left: '80%',
					}}
				>
					Soon!
				</span> */}
			</Box>
			<Box className={classes.buttonContainer}>
				<IconButton
					onClick={() => {
						window.open('https://twitter.com/cetsnft');
					}}
					className={classes.icon}
				>
					<FontAwesomeIcon icon={faTwitter} />
				</IconButton>
				<img
					onClick={() => {
						window.open('https://discord.gg/creckhouse');
					}}
					src={game}
					alt="game"
					className={classes.icon}
				/>
				<IconButton
					className={classes.icon}
					onClick={() => {
						window.open('https://magiceden.io/marketplace/cets_on_creck');
					}}
				>
					<MagicEden />
				</IconButton>
				<ConnectButton style={{ color: `${Colors.Background} !important` }}>
					{walletAddr ? `${walletAddr}` : 'Connect wallet'}
				</ConnectButton>
			</Box>
		</Box>
	);
}
